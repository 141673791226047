@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');

html {
    font-size: 16px;
    line-height: 1rem;
}

body {
  margin: 0;
  color: var(--primary);
  background: var(--festival-secondary);
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: var(--festival-secondary);
  background: var(--festival-primary);
}

p, div, b, a, h1, h2, h3, h4, h5, h6 {
  color: var(--primary);
  text-decoration: none;
}

p {
  margin-top: 0;

}

h1 {
  font-size: 2rem;
  line-height: 2rem;
}
h2 {
  font-size: 3.75rem;
  line-height: 4rem;
}

h1, h2, h3, h4 {

  margin-bottom: 1rem;
  }
  h1:first-child, h2:first-child, h3:first-child {
    margin-top: 0;
  }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
