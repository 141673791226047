
:root {
    --primary: #0267FF;
    /* --secondary: #999966; */
    /* --secondary: linear-gradient(0deg, rgba(174,174,216,1) 0%, rgba(229,228,244,1) 50%, rgba(174,174,216,1) 100%); */
    /* --secondary: linear-gradient(0deg, rgba(209,119,76,1) 0%, rgba(73,65,201,1) 11%, rgba(246,152,226,1) 43%, rgba(227,93,209,1) 52%, rgba(207,64,150,1) 89%, rgba(59,36,6,1) 100%);; */
    --secondary: hsl(0deg 95% 3%);
    --festival-primary: #FF503D;
    --festival-primary-translucent: #FF503D20;
    --festival-secondary: #374032;
    --inner-width: 74rem;
    --inner-width-narrow: 44rem;
  }