@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap);
html {
    font-size: 16px;
    line-height: 1rem;
}

body {
  margin: 0;
  color: var(--primary);
  background: var(--festival-secondary);
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  color: var(--festival-secondary);
  background: var(--festival-primary);
}

p, div, b, a, h1, h2, h3, h4, h5, h6 {
  color: var(--primary);
  text-decoration: none;
}

p {
  margin-top: 0;

}

h1 {
  font-size: 2rem;
  line-height: 2rem;
}
h2 {
  font-size: 3.75rem;
  line-height: 4rem;
}

h1, h2, h3, h4 {

  margin-bottom: 1rem;
  }
  h1:first-child, h2:first-child, h3:first-child {
    margin-top: 0;
  }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
    --primary: #0267FF;
    /* --secondary: #999966; */
    /* --secondary: linear-gradient(0deg, rgba(174,174,216,1) 0%, rgba(229,228,244,1) 50%, rgba(174,174,216,1) 100%); */
    /* --secondary: linear-gradient(0deg, rgba(209,119,76,1) 0%, rgba(73,65,201,1) 11%, rgba(246,152,226,1) 43%, rgba(227,93,209,1) 52%, rgba(207,64,150,1) 89%, rgba(59,36,6,1) 100%);; */
    --secondary: hsl(0deg 95% 3%);
    --festival-primary: #FF503D;
    --festival-primary-translucent: #FF503D20;
    --festival-secondary: #374032;
    --inner-width: 74rem;
    --inner-width-narrow: 44rem;
  }
